/*eslint-disable*/
import React, { useEffect, useState } from "react";
import moment from 'moment'

//Firebase
import db from 'firebase'
import { collection, query, orderBy, limit,  getDocs } from "firebase/firestore";

// react plugin for creating charts
import ChartistGraph from "react-chartist";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";
import AccessTime from "@material-ui/icons/AccessTime";

// core components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

//IAQ Device List
import deviceIAQList from 'DeviceList/deviceIAQList'
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);


export default function Charts(props) {
  const dataPoint = 13
  const updateFeq = 300000
  const classes = useStyles();
  const [overallStatus, setOverallStatus] = useState("info")
  const [lastUpdateTime,setLastUpdateTime] = useState()

  const [chartOptions, setChartOptions] = useState({
    high: 6000,
    low: 0,
    fullWidth: true,
  });  
  const [chartData, setChartData] = useState({
    labels: [1,2,3],
    series: [[1,1],[2,2],[3,3]]
})

  



useEffect(() => {
  //console.log("IAQChart effect", props.DBName)
  getChartData(props.SensorMetric)
  const interval = setInterval(() => getChartData(props.SensorMetric), updateFeq);
  return () => {
    console.log("clean up IAQ Chart"); 
    //clearInterval(interval)
    };
}, [props]);

const getChartData = async (sensorMetric) => {
    //console.log("deviceList",deviceIAQList)
    var dbIndex =  deviceIAQList.findIndex(toilet => toilet.type == props.ToiletType && toilet.floor == props.Floor && toilet.cubicleId == props.CublicleID)
    //console.log("dbIndex", dbIndex , "type" , props.ToiletType,"Floor",props.Floor)
    var dBName = deviceIAQList[dbIndex].dbname
    //console.log("IAQChart", dBName)
    var timeAxis = [];
    var deviceData = [];
    
    const StatusRef = collection(db, dBName);

      const q =  query(StatusRef,orderBy("timestamp","desc"), limit(dataPoint))
      const querySnapshot = await getDocs(q);
        deviceData = []
        timeAxis = [];
         querySnapshot.forEach((doc) => {   
          deviceData.push(doc.data()[sensorMetric])
          timeAxis.push(moment(doc.data().timestamp).format('HH:mm'));
          //console.log("Time: ", moment().format('HH:mm'), "data length now", deviceData.length)
          //console.log("timeAxis: ",timeAxis)
          //console.log("deviceData: ",sensorMetric," : ",deviceData)
        })

      
      if (deviceData.length == dataPoint && timeAxis.length == dataPoint) {
          setChartOptions({
              high:Math.max(...deviceData)*1.05,
              low: Math.min(...deviceData)*0.95,
              fullWidth: true,
              lineSmooth: Chartist.Interpolation.cardinal({
                tension: 10,
              }),
              showPoint: false,
              chartPadding :{
                top:15,
                right: 10,
                bottom:0,
                left: 0
              } ,
          
          })
          //console.log("data length after", deviceData.length)
          setChartData({
                labels: timeAxis.reverse(),
                series: [deviceData.reverse()],
          });
          
          sensorStatus(sensorMetric, deviceData[deviceData.length-1])
          setLastUpdateTime(moment(timeAxis[timeAxis.length-1].timestamp).format('YYYY-MM-DD HH:mm'))
          console.log("last Update",moment(timeAxis[timeAxis.length-1].timestamp).format('YYYY-MM-DD HH:mm'))
          }
}


 const sensorStatus = (sensorMetric,sensorData) =>{
    
    switch (sensorMetric){
                
      case "co2" : 
          if (sensorData <= 800) { setOverallStatus("success")} ;
          if (sensorData > 800 && sensorData < 1000 ) { setOverallStatus("warning")} ;
          if (sensorData >= 1000 ) { setOverallStatus("danger")} ;
      break;
      
      case "tvoc" : 
          if (sensorData <= 300) { setOverallStatus("success")} ;
          if (sensorData > 300 && sensorData < 500 ) { setOverallStatus("warning")} ;
          if (sensorData >= 500 ) { setOverallStatus("danger")} ;
      break;
      
      case "humidity" : 
          if (sensorData >=40 && sensorData <= 70) { setOverallStatus("success")} ;
          if (sensorData < 40 || sensorData > 70 ) { setOverallStatus("warning")} ;          
      break;

      case "nh3" : 
          if (sensorData <= 2000) { setOverallStatus("success")} ;
          if (sensorData > 2000 && sensorData <= 5000 ) { setOverallStatus("warning")} ;
          if (sensorData > 5000 ) { setOverallStatus("danger")} ;
      break;

      case "h2s" : 
          if (sensorData<= 100) { setOverallStatus("success")} ;
          if (sensorData> 100 && sensorData<300 ) { setOverallStatus("warning")} ;
          if (sensorData>= 300 ) { setOverallStatus("danger")} ;
      break;

      case "hcho" : 
          if (sensorData <= 70) { setOverallStatus("success")} ;
          if (sensorData > 70 && sensorData <100 ) { setOverallStatus("warning")} ;
          if (sensorData >= 100 ) { setOverallStatus("danger")} ;
      break;
      
      case "pm2" : 
          if (sensorData <= 25) { setOverallStatus("success")} ;
          if (sensorData > 25 && sensorData <37.5 ) { setOverallStatus("warning")} ;
          if (sensorData >= 37.5 ) { setOverallStatus("danger")} ;
      break;

      case "temperature" : 
          if (sensorData >= 18 && sensorData <= 30 ) { setOverallStatus("success")} ;
          if ((sensorData > 15 && sensorData <18  )|| (sensorData >30 && sensorData <35)) { setOverallStatus("warning")} ;
          if (sensorData >=35 || sensorData <=15) { setOverallStatus("danger")} ;
      break;
    }
    
  }

  
  return (
    <div>
   
          <Card chart>
            <CardHeader color={overallStatus}>
              <ChartistGraph
                className="ct-chart-white-colors"
                data={chartData}
                type="Line"
                options= {chartOptions}
                
                
              />
            </CardHeader>
            <CardBody>
             <h4 className={classes.cardTitle}>{props.ChartName}</h4>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> Last Update {lastUpdateTime} 
              </div>
            </CardFooter>
          </Card>
      
    </div>
  );
}
