import React, { useEffect, useState,useContext } from "react";
import moment from "moment";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

import OpacityIcon from "@material-ui/icons/Opacity";

//React Icon
import { FaTemperatureLow,FaWind } from "react-icons/fa";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Tasks from "components/Tasks/Tasks.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardIconSquare from "components/Card/CardIconSquare";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { bugs, website, server } from "variables/general.js";
import Button from "components/CustomButtons/Button.js";

//Custom Icon
import { ReactComponent as SmartToiletIcon } from "components/Icon/MF.svg";
import { ReactComponent as AccessibilityToilet } from "components/Icon/DA.svg";
import { ReactComponent as MaleToilet } from "components/Icon/MaleToilet.svg";
import { ReactComponent as FemaleToilet } from "components/Icon/FemaleToilet.svg";
import { ReactComponent as FallDetect } from "components/Icon/FallDetect.svg";
import { ReactComponent as PeopleExist } from "components/Icon/PeopleExist.svg";

//Firebase 
import { AuthContext,db } from "firebase";
import { collection, query, where, getDocs, onSnapshot, querySnapshot, onSnapshotsInSync } from "firebase/firestore";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

//import { getFallStatus, getMoveStatus } from "variables/getDemoData";

const useStyles = makeStyles(styles);

export default function Demo() {
  const classes = useStyles();
  const currentUser = useContext(AuthContext)
  const [currentUserEmail, setCurrentUserEmail] = useState(currentUser.user.email)
  const [subscribed, setSubscribed] = useState(false)
  const [sensorDisplay,setSensorDisplay] = useState([])

  

  //Firebase Fetch Status
  useEffect( () => {
    getFallDetectStatus()
    return () => {
      console.log("clean up Fall Detect"); 
      //clearInterval(interval)
      };
  },[])

   
  const getFallDetectStatus = (TenantId) => {
    console.log ("getFallDetectStatus")
    const fallDetectRef = collection(db, "CGO_falldetectSensor_Status");
    const fallDetectSensor =  query(fallDetectRef)
    const unsubscribe =  onSnapshot(fallDetectSensor,  (querySnapshot) => {
      console.log ("onSnapshot")
      var tempDisplay=[]
      var id = 0

      console.log("snapshot length",querySnapshot.size)
      querySnapshot.forEach((doc)=>{
        var sensor = doc.data()
        //console.log("Fall Detect", doc.data())
        id ++ 
        tempDisplay.push(
          displaySensor(sensor.cubicleID,sensor.hkdatetime,sensor.moveStateColor,sensor.fallStateColor, Math.round(sensor.tvoc), Math.round(sensor.temperature), Math.round(sensor.humidity)) 
          )
      })
      
      setSensorDisplay(tempDisplay)
          
      console.log("sensorStatus",sensorDisplay)
  
    })
  }

  
  const displaySensor = (id, hkdatetime,moveState, fallstate, tvoc, temperature, humidity) => {
    //console.log("hkdatatime",hkdatetime)
    //console.log("Fall Detect", moment(new Date()).diff(moment(hkdatetime),'second'))
    var timeGap = moment(new Date()).diff(moment(hkdatetime),'second')
    return (
 
        <GridItem key={id} xs={12} sm={6} md={4} lg={3} xl={2}>
          <Card>
            <CardHeader color = "info" stats icon>            
              <h5 className={classes.cardTitle}>ID: {id}</h5>
            </CardHeader>
        
          <CardBody>      
          <GridContainer justify="flex-start">
            <GridItem xs={6} sm={6} md={6} lg={6} xl={6}>
              <GridContainer>
                <GridItem xs={2} sm={2} md={2} lg={2} xl={1}>
                  <OpacityIcon style={{ fontSize: 20 }} />
                  <p  style={{fontSize: 10, marginTop: 0, marginLeft: 0, whiteSpace:"nowrap" }}>
                    {humidity}
                  </p>
                </GridItem>
                <GridItem xs={2} sm={2} md={2} lg={2} xl={1}>
                  <FaTemperatureLow style={{ fontSize: 20 }} />
                  <p  style={{fontSize: 10, marginTop: 0, marginLeft: 0, whiteSpace:"nowrap" }}>
                    {temperature}
                  </p>
                </GridItem>
                <GridItem xs={2} sm={2} md={2} lg={2} xl={1}>
                  <FaWind style={{ fontSize: 20 }} />
                  <p  style={{fontSize: 10, marginTop:0, marginLeft: 0, whiteSpace:"nowrap" }}>
                    {tvoc}
                  </p>
                </GridItem>
              </GridContainer>
            </GridItem>

            <GridItem xs={6} sm={6} md={8} lg={8} xl={8}>
              <GridContainer>
                <GridItem xs={6} sm={6} md={6} lg={6} xl={6}>
                  <CardIconSquare color={moveState}>
                    <PeopleExist
                      style={{
                        width: "90%",
                        height: "90%",
                        position: "relative",
                        left: "25%",
                        top: "4",
                      }}
                    />
                  </CardIconSquare>
                </GridItem>
                <GridItem xs={6} sm={6} md={6} lg={6} xl={6}>
                  <CardIconSquare color={fallstate}>
                    {" "}
                    <FallDetect
                      style={{
                        width: "90%",
                        height: "auto",
                        position: "relative",
                        left: "20%",
                        top: "4",
                      }}
                    />
                  </CardIconSquare>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
          <GridContainer justify="flex-end"><span style={{fontSize:10 ,color:(timeGap<300)?("black"):("red")}}>Last Update:   {hkdatetime}</span></GridContainer>
        </CardBody>
        </Card>
        </GridItem>
   
    )
  }



return (
  <div>
    <GridContainer> {sensorDisplay}</GridContainer>
  </div>
  )
}
