import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

//Firebase
import { AuthContextProvider, useAuthState } from './firebase'

//Layout
import AuthLayout from "layouts/Auth.js";
//import RtlLayout from "layouts/RTL.js";
import AdminLayout from "layouts/Admin.js";
import ForgetPasswordPage from "views/Pages/ForgetPasswordPage";

//Compoent
import ResetPasswordPage from "views/Pages/ResetPasswordPage";

import "assets/scss/material-dashboard-pro-react.scss?v=1.10.0";

const AuthenticatedRoute = ({ component: C, ...props }) => {
    const { isAuthenticated } = useAuthState()
    //console.log(`AuthenticatedRoute: ${isAuthenticated}`)
    return (
      <Route
        {...props}
        render={routeProps =>
          isAuthenticated ? <C {...routeProps} /> : <Redirect to="/Auth" />
        }
      />
    )
}

const UnauthenticatedRoute = ({ component: C, ...props }) => {
    const { isAuthenticated } = useAuthState()
    //console.log(`UnauthenticatedRoute: ${!isAuthenticated}`)
    return (
      <Route
        {...props}
        render={routeProps =>
          !isAuthenticated ? <C {...routeProps} /> : <Redirect to="/admin" />
        }
      />
    )
  }

  export default function App() {
    return(
        <AuthContextProvider>
          <BrowserRouter>
          <Switch>    
            <AuthenticatedRoute  path="/admin" component={AdminLayout} />
            <UnauthenticatedRoute  path="/Auth" component={AuthLayout} /> 
            <Redirect from="/" to="/admin" />
            <Redirect from='/*' to='/admin' /> 
            </Switch>
            </BrowserRouter>
        </AuthContextProvider>
    );
    
    }