import { useState, useEffect, useContext, createContext } from 'react'
// Import the functions you need from the SDKs you need
import { getFirestore } from "firebase/firestore";
import {getStorage} from "firebase/storage";
import { getAuth, onAuthStateChanged } from 'firebase/auth' //@firebase/auth before
import { initializeApp } from "firebase/app"; 
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries



// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAdfZrMQf8CHDtDszCLOwd0KD8eFJ1zlm8",
  authDomain: "cgo-smart-dashboard.firebaseapp.com",
  projectId: "cgo-smart-dashboard",
  storageBucket: "cgo-smart-dashboard.appspot.com",
  messagingSenderId: "230798896516",
  appId: "1:230798896516:web:892d107f87abbb554bc1d1",
  measurementId: "G-3YV2KX69TH"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig)
export const db = getFirestore();
//const analytics = getAnalytics(app);

export const storage = getStorage();

//AuthContext
export const AuthContext = createContext()

export const AuthContextProvider = props => {
  const [user, setUser] = useState()
  const [error, setError] = useState()

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(),setUser, setError)
    return () => unsubscribe()
  }, [])
  return <AuthContext.Provider value={{ user, error }} {...props} />
}

export const useAuthState = () => {
  const auth = useContext(AuthContext)
  return { ...auth, isAuthenticated: auth.user != null }
}

export default  db

