//Util
import moment from "moment";
import {React, useState, useEffect} from 'react'

//Firebase
import db from 'firebase'
import { collection, query, where, getDocs, onSnapshot } from "firebase/firestore";
// Card
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";

//Style
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
const useStyles = makeStyles(styles);
//Icon
import { ReactComponent as AccessibilityIcon } from "components/Icon/DA.svg";
import AccessTime from "@material-ui/icons/AccessTime";

//Modal
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import { Rowing } from "@material-ui/icons";
import Paper from '@material-ui/core/Paper'
import AccessToiletModal from "views/Modal/AccessToiletModal";


export default function AccessibleToiletUsage(props) {

  const CubicleStatusRef = collection(db, "APB_Cubicle_Status");
  //const PeopleCountRef = collection(db, "APB_PeopleCount_Status");
  const [overallStatus, setOverallStatus] = useState("info")
  const [peopleIn, setPeopleIn] = useState("")
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const classes = useStyles();
  const [lastUpdate,setLastUpdate] = useState()

  useEffect(() => {
    fetchStatus()
    const interval = setInterval(() => fetchStatus(), 60000);
    return () => {clearInterval(interval)};
  }, [props])

  /*
  useEffect(() => {
    fetchPeopleCount()
    const interval = setInterval(() =>   fetchPeopleCount(), 60000);
    return () => {clearInterval(interval)};
  }, [props])

  const fetchPeopleCount = async() =>{
    const peopleCount = query(PeopleCountRef)
    const querySnapshotPeopleCount = await getDocs(peopleCount)
    querySnapshotPeopleCount.forEach((doc =>{
      if (doc.data().floor == props.Floor && doc.data().type=="female") {
        setPeopleIn(doc.data().peoplein)
      }
    }))
  }
  */

  const fetchStatus = async () => {
    var tempStatus = [] 
    var  tempUpdateTime = [];
    var tempPeopleIn = "0"
    const cubicle =  query(CubicleStatusRef)
    const querySnapshotCubicle = await getDocs(cubicle);
      //console.log("querySnapshot ",querySnapshot.size)
      querySnapshotCubicle.forEach((doc) => {
          //console.log("data" , doc.data())
          if (doc.data().floor == props.Floor && doc.data().type == "zaccessible") {
            tempStatus.push(doc.data().status)
            tempUpdateTime.push(doc.data().hkdatetime)
            tempUpdateTime.sort().reverse()
            tempPeopleIn = doc.data().occupancy
           }
           setLastUpdate(moment(tempUpdateTime[0]).format('YYYY-MM-DD HH:mm'))
           setPeopleIn(tempPeopleIn)

    })
    if (tempStatus.includes("login")   ) {
        setOverallStatus("danger");
    }else {
        setOverallStatus("success");
      }
  }

  return(
    <div>
          <Card>
            
            <CardHeader color="info" stats icon>
              <a href="#" style={{ color: "white" }} onClick={() => handleOpen()}>
                <CardIcon color={overallStatus} >
                  <AccessibilityIcon/>
                </CardIcon>
              </a>
    
              <p className={classes.cardCategory}>Daily People Counting</p>
              <h3 className={classes.cardTitle}>
              {peopleIn}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
              <AccessTime /> Last Update {lastUpdate}
              </div>
            </CardFooter>
          </Card>
           <Modal
           open={open}
           onClose={handleClose}
           aria-labelledby="modal-modal-title"
           aria-describedby="modal-modal-description"
           style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
         >
           <Paper
            style={{
              width: "95%",
              height: "100%",
              backgroundColor: "#767676",
              display: "flex",
              alignSelf: "center",
              alignItems: "flex-start",
              justifyContent: "center",
              overflow: 'auto'
            }}
          >
           <AccessToiletModal Floor={props.Floor} ModalHandleClose={handleClose}/>
          </Paper>

           </Modal>
    </div>
    
  )
}