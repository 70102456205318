/*eslint-disable*/
import React, { useEffect, useState } from "react";
import moment from 'moment'

//Firebase
import db from 'firebase'
import {collection, query, where, getDocs} from "firebase/firestore";

// react plugin for creating charts
import ChartistGraph from "react-chartist";


// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import AccessTime from "@material-ui/icons/AccessTime";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import TextField from "@material-ui/core/TextField";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import Badge from "components/Badge/Badge.js";
import Button from "components/CustomButtons/Button.js";
//Icon 
import {BiWind as IAQIcon}  from 'react-icons/bi';

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import stylesChart from "assets/jss/material-dashboard-pro-react/views/chartsStyle"
import Divider from '@material-ui/core/Divider';
import Paginations from "views/Components/Pagination"

const useStyles = makeStyles(styles);
const useStylesChart = makeStyles(stylesChart);


export default function Charts(props) {
  
  const classes = useStyles();
  const [lastUpdateTime,setLastUpdateTime] = useState(moment().subtract(1, 'day').format('YYYY-MM-DD 23:59'))
  const [queryDate1, setQueryData1] = useState(moment().subtract(1, 'day').format('YYYY-MM-DD'))
  const [queryDate2, setQueryData2] = useState('')
  const [currentFloor,setCurrentFloor] = useState("1")
  const [currentToiletType, setCurrentToiletType] = useState("female")
  const [chartOptions, setChartOptions] = useState([{"series": [70,20,10] },{"series": [70,20,10] },{"series": [70,20,10] },{"series": [70,20,10] },{"series": [70,20,10] },{"series": [70,20,10] },{"series": [70,20,10] }]); 

  var dummy= []
  for (var i=0; i<=7; i++){
    dummy.push({
      donut: true,
      donutWidth: 20,
      startAngle: 270,
      total: 200,
      showLabel: false,
      width: "70%"
    })
  }
  const [chartData, setChartData] = useState(dummy)

  const [sensorMetric,setSensorMetric] = useState(["co2","tvoc","hcho","pm2","h2s","nh3","temperature","humidity"])

const startOfDayUTC = (queryDate) => { 
  return moment.utc(moment(queryDate).startOf('day').subtract(0, 'days').format()).format()
}

const endOfDayUTC = (queryDate) => {
  return moment.utc(moment(queryDate).endOf('day').subtract(0, 'days').format()).format()
}

//DB Number Pad
const zeroPad = (num, places) => String(num).padStart(places, '0')

const StatusRef = collection(db, "CGO_IAQ_DailySummary");



useEffect(() => {
  //console.log("IAQChart effect", props.DBName)
  
  getChartData()
  //const interval = setInterval(() => getChartData(props.DBName, props.SensorMetric), 60000);
 // return () => {clearInterval(interval)};
 return () => {
  console.log("clean up IAQ Summary"); 
  //clearInterval(interval)
  };
}, [props,  queryDate1,queryDate2, currentToiletType, currentFloor]);

const getChartData =  async () => {
    var metric = []
    var options = []
    var q1 =  query(StatusRef, where("date","==",moment(queryDate1).format('YYYY-MM-DD')))
    //var q2 =  query(StatusRef, where("date","==",moment(queryDate2).format('YYYY-MM-DD')))
    const queryDoc1 = await getDocs(q1);
    //const queryDoc2 = await getDocs(q2);
      queryDoc1.forEach((device) => {

          if (device.data().type == currentToiletType && device.data().floor ==currentFloor){
          //console.log("Device Data", device.data(), "type:",device.data().type)
      
              sensorMetric.forEach((sensor) => {
                 if (Array.isArray(device.data()[sensor])){
                  var total = (device.data()[sensor][0] + device.data()[sensor][1] +device.data()[sensor][2])*2
                  //console.log("sensor",sensor,"success",device.data()[sensor][0],"total",total)
                  
                    metric.push({"series": device.data()[sensor] })
                    options.push({
                      donut: true,
                      donutWidth: 20,
                      startAngle: 270,
                      total: total,
                      showLabel: false,
                      width: "70%"
                    }); 
                  }
              })
          }
        })
    
      //console.log(metric)
      if(metric.length > 0 ){
      setChartData(metric);
      setChartOptions(options)
      }else {
        setChartData([{series: [0,0,0]},{series: [0,0,0]},{series: [0,0,0]},{series: [0,0,0]},{series: [0,0,0]},{series: [0,0,0]},{series: [0,0,0]},{series: [0,0,0]}])
        setChartOptions([{
            donut: true,
            donutWidth: 20,
            startAngle: 270,
            total: 200,
            showLabel: false,
            width: "70%"
        }])
      }

    return () => unsubscribe()
    
}

  const handleClick = (floor) =>{
    if (floor == "9" || floor == "8") {setCurrentFloor(floor)}
  }

  const handleDateChange = (e) => {
      if (e.target.id=="date1"){setQueryData1(moment(e.target.value).format("YYYY-MM-DD"));}
      if (e.target.id=="date2"){setQueryData2(moment(e.target.value).format("YYYY-MM-DD"));}
  };

  const handleType = ((type =>{
    setCurrentToiletType(type)
  }))

  
  return (
    <div>
          <Card chart>
            <CardHeader color="info" stats icon>
            <CardIcon color="info" >
                <IAQIcon/>
              </CardIcon>
              
              <h4 className={classes.cardTitle} style={{position:'relative',top:25,right:20}}>{props.ChartName}</h4>
           
            </CardHeader>
            <CardBody>
            <GridContainer>
              <GridItem> 
                <form className={classes.container} noValidate>
                    <TextField
                      id="date1"
                      label="Date1"
                      type="date"
                      onChange={handleDateChange}
                      defaultValue={queryDate1}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </form>
              </GridItem>
              <GridItem style={{position:'relative', top: 25}} >{(queryDate1 != '' && queryDate1 != 'Invalid date')?(<Badge color="info">{queryDate1}</Badge>):(<div></div>)}</GridItem>
             </GridContainer>
 
             <Divider/>
            
             <GridContainer >
  
              <GridItem xs={6} sm={3} md={3} lg={3} style={{marginTop:-50}}>
                  <div style={{textAlign: 'center'}}>
                      <h6 style={{position:'relative', top:90}}>{sensorMetric[0]}</h6>
                      <ChartistGraph data={chartData[0]} type="Pie" options={chartOptions[0]}/>
                  </div>
              </GridItem>

              <GridItem xs={6} sm={3} md={3} lg={3} style={{marginTop:-50}}>
                  <div style={{textAlign: 'center'}}>
                      <h6 style={{position:'relative', top:90}}>{sensorMetric[1]}</h6>
                      <ChartistGraph data={chartData[1]} type="Pie" options={chartOptions[1]}/>
                  </div>
              </GridItem>


              <GridItem xs={6} sm={3} md={3} lg={3} style={{marginTop:-50}}>
                  <div style={{textAlign: 'center'}}>
                      <h6 style={{position:'relative', top:90}}>{sensorMetric[2]}</h6>
                      <ChartistGraph data={chartData[2]} type="Pie" options={chartOptions[2]}/>
                  </div>
              </GridItem>


              <GridItem xs={6} sm={3} md={3} lg={3} style={{marginTop:-50}}>
                  <div style={{textAlign: 'center'}}>
                      <h6 style={{position:'relative', top:90}}>{sensorMetric[3]}</h6>
                      <ChartistGraph data={chartData[3]} type="Pie" options={chartOptions[3]}/>
                  </div>
              </GridItem>

              <GridItem xs={6} sm={3} md={3} lg={3} style={{marginTop:-50}}>
                  <div style={{textAlign: 'center'}}>
                      <h6 style={{position:'relative', top:90}}>{sensorMetric[4]}</h6>
                      <ChartistGraph data={chartData[4]} type="Pie" options={chartOptions[4]}/>
                  </div>
              </GridItem>
           

              <GridItem xs={6} sm={3} md={3} lg={3} style={{marginTop:-50}}>
                  <div style={{textAlign: 'center'}}>
                      <h6 style={{position:'relative', top:90}}>{sensorMetric[5]}</h6>
                      <ChartistGraph data={chartData[5]} type="Pie" options={chartOptions[5]}/>
                  </div>
              </GridItem>
           

              <GridItem xs={6} sm={3} md={3} lg={3} style={{marginTop:-50}}>
                  <div style={{textAlign: 'center'}}>
                      <h6 style={{position:'relative', top:90}}>{"℃"}</h6>
                      <ChartistGraph data={chartData[6]} type="Pie" options={chartOptions[6]}/>
                  </div>
              </GridItem>


              <GridItem xs={6} sm={3} md={3} lg={3} style={{marginTop:-50}}>
                  <div style={{textAlign: 'center'}}>
                      <h6 style={{position:'relative', top:90}}>{"RH"}</h6>
                      <ChartistGraph data={chartData[7]} type="Pie" options={chartOptions[7]}/>
                  </div>
              </GridItem>

             </GridContainer>
            </CardBody>

            <CardFooter chart>            
              <GridContainer>

                  <GridItem>
                    {(currentToiletType == "female")?(<Button round color="info">Female</Button>):(<Button simple color="info" onClick={()=>handleType("female")}>Female</Button>)}
                    {(currentToiletType == "male")?(<Button round color="info">Male</Button>):(<Button simple color="info" onClick={()=>handleType("male")} >Male</Button>)}
                    {/*(currentToiletType == "zaccessible")?(<Button round color="info">Accessible</Button>):(<Button simple color="info" onClick={()=>handleType("zaccessible")} >Accessible</Button>)*/}
                  </GridItem> 
                  {/*
                  <GridItem>
                    <Paginations
                      pages={[
                        { text: 1, active: (currentFloor == "1")?(true):(false)},
                        { text: 2, active: (currentFloor == "2")?(true):(false)},
                        { text: 3, active: (currentFloor == "3")?(true):(false)},
                        { text: 4, active: (currentFloor == "4")?(true):(false)},
                        { text: 5, active: (currentFloor == "5")?(true):(false)},
                        { text: 6, active: (currentFloor == "6")?(true):(false)},
                        { text: 7, active: (currentFloor == "7")?(true):(false)},
                        { text: 8, active: (currentFloor == "8")?(true):(false)},
                        { text: 9, active: (currentFloor == "9")?(true):(false)},
                      ]}
                      color="info"
                      onClick={handleClick}
                    />
                  </GridItem>
                    */}
              </GridContainer>
              
            </CardFooter>
            <CardFooter>
            <div className={classes.stats}>
                <AccessTime /> Update Every 24 Hours - Last Update {lastUpdateTime} 
              </div>
            </CardFooter>
          </Card>
    </div>
  );
}
